// ==============================
// * PAGE DESIGNER: HIGHLIGHT ROW
//
// ? This file gathers all the custom highlight row CSS.
// ==============================

@import "../../variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "slider";

.highlighted-section {
    padding-bottom: 20px;
    background-color: $grey2;
    overflow: hidden;

    @include media-breakpoint-up(md) {
        background-color: $white;
    }

    @include media-breakpoint-up(lg) {
        padding-bottom: 0;
    }
}

.highlight-row {

    @include media-breakpoint-up(md) {
        &[data-components="1"] {
            .experience-component {
                width: 100%;

                .highlight-content {
                    a {
                        flex-flow: row nowrap;
                    }

                    &__image {
                        max-width: 66vw;

                        img {
                            max-height: 100vh;
                        }
                    }

                    &__content {
                        max-width: 32vw;
                    }
                }
            }
        }

        &[data-components="2"] {
            .experience-component {
                width: calc(100% / 2);

                .highlight-content {
                    &__image {
                        img {
                            max-height: 55vh;
                        }
                    }
                }
            }
        }

        &[data-components="3"] {
            .experience-component {
                width: calc(100% / 3);
            }
        }
    }

    &.slider {
        padding-bottom: 20px;

        @include media-breakpoint-up(lg) {
            padding-bottom: 0;
        }

        .slick-slide {
            margin: 0;
        }

        .slick-dots {
            left: 35px;

            @include media-breakpoint-up(lg) {
                left: 60px;
            }
        }
    }

    .experience-component {
        width: 100%;

        .highlight-content {
            &__image {
                img {
                    object-fit: cover;
                    //width: 100%;
                    max-height: 47vh;
                    height: 600px;
                }
            }
        }
    }
}
